import { auth } from "@/firebase";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      authRequired: true,
      breadcrumb: [],
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () => import("../views/Companies.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["companies"],
    },
  },
  {
    path: "/companies/create",
    name: "Company create",
    component: () => import("../views/CompanyCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["companies", "create"],
    },
  },
  {
    path: "/companies/:companyId",
    name: "Company",
    component: () => import("../views/Company.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["companies", "company"],
    },
  },
  {
    path: "/companies/:companyId/edit",
    name: "Company edit",
    component: () => import("../views/CompanyEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["companies", "company", "edit"],
    },
  },
  {
    path: "/companies/:companyId/personnel/create",
    name: "Company person create",
    component: () => import("../views/UserCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["companies", "company", "personnel", "create"],
    },
  },
  {
    path: "/companies/:companyId/personnel/:userId",
    name: "Company person",
    component: () => import("../views/User.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["companies", "company", "personnel", "user"],
    },
  },
  {
    path: "/companies/:companyId/personnel/:userId/edit",
    name: "Company person edit",
    component: () => import("../views/UserEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["companies", "company", "personnel", "user", "edit"],
    },
  },
  {
    path: "/complete",
    name: "Complete",
    component: () => import("../views/Complete.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/Forgot.vue"),
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/Partners.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partners"],
    },
  },
  {
    path: "/partners/create",
    name: "Partner create",
    component: () => import("../views/PartnerCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partners", "create"],
    },
  },
  {
    path: "/partners/:partnerId",
    name: "Partner",
    component: () => import("../views/Partner.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partners", "partner"],
    },
  },
  {
    path: "/partners/:partnerId/edit",
    name: "Partner edit",
    component: () => import("../views/PartnerEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partners", "partner", "edit"],
    },
  },
  {
    path: "/partners/:partnerId/personnel/create",
    name: "Partner person create",
    component: () => import("../views/UserCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partners", "partner", "personnel", "create"],
    },
  },
  {
    path: "/partners/:partnerId/personnel/:userId",
    name: "Partner person",
    component: () => import("../views/User.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partners", "partner", "personnel", "user"],
    },
  },
  {
    path: "/partners/:partnerId/personnel/:userId/edit",
    name: "Partner person edit",
    component: () => import("../views/UserEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["partners", "partner", "personnel", "user", "edit"],
    },
  },
  {
    path: "/personnel",
    name: "Personnel",
    component: () => import("../views/Personnel.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["personnel"],
    },
  },
  {
    path: "/personnel/create",
    name: "Personnel create",
    component: () => import("../views/UserCreate.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["personnel", "create"],
    },
  },
  {
    path: "/personnel/:userId",
    name: "Personnel user",
    component: () => import("../views/User.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["personnel", "user"],
    },
  },
  {
    path: "/personnel/:userId/edit",
    name: "Personnel user edit",
    component: () => import("../views/UserEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["personnel", "user", "edit"],
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile"],
    },
  },
  {
    path: "/profile/edit",
    name: "Profile edit",
    component: () => import("../views/ProfileEdit.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile", "edit"],
    },
  },
  {
    path: "/profile/auth",
    name: "Profile account edit",
    component: () => import("../views/ProfileEditAuth.vue"),
    meta: {
      authRequired: true,
      breadcrumb: ["profile", "auth"],
    },
  },
  {
    path: "/sign-in",
    name: "Sign-in",
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/verify-email/:token",
    name: "Verify email",
    component: () => import("../views/Verify.vue"),
  },
  {
    path: "/verify-password/:token",
    name: "Verify password",
    component: () => import("../views/Verify.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeResolve(async (to, from, next) => {
  const currentUser = auth.currentUser;
  const idTokenResult = await currentUser?.getIdTokenResult();
  if (to.meta.authRequired && !currentUser) {
    return next("/sign-in");
  } else if (to.meta.authRequired && idTokenResult?.claims.password_reset) {
    return next("/complete");
  } else {
    return next();
  }
});

export default router;
