import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    notification: {},
    notifications: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async getNotifications({ commit }) {
    try {
      commit("wait");
      const getNotifications = httpsCallable(functions, "getNotifications");
      const res = await getNotifications();

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("setNotifications", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const notification = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default notification;
