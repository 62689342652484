import { db, functions } from "@/firebase";
import router from "@/router";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    status: {},
    companies: [],
    company: {},
    fivaldiCustomers: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createCompany({ commit }, data) {
    try {
      commit("wait");
      const docRef = await addDoc(collection(db, "companies"), data);
      const company = data;
      company.id = docRef.id;

      commit("setSuccess", "company_created");
      commit("addCompany", company);
      router.push("/companies");
    } catch (error) {
      commit("failure", error);
    }
  },
  async getCompanies({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "companies"));
      const companies = querySnapshot.docs.map((doc) => {
        const company = doc.data();
        company.id = doc.id;
        return company;
      });

      commit("setCompanies", companies);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getCompany({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "companies", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const company = docSnap.data();
        company.id = docSnap.id;
        commit("setCompany", company);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getFivaldiCustomers({ commit }) {
    try {
      commit("wait");
      const getFivaldiCustomers = httpsCallable(
        functions,
        "getFivaldiCustomers"
      );
      const res = await getFivaldiCustomers();

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("setFivaldiCustomers", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateCompany({ commit }, { id, data }) {
    try {
      commit("wait");
      const docRef = doc(db, "companies", id);
      await updateDoc(docRef, data);
      const company = data;
      company.id = id;

      commit("setSuccess", "company_updated");
      commit("replaceCompany", company);
      router.push(`/companies/${company.id}`);
    } catch (error) {
      console.log(error);
      commit("failure", error);
    }
  },
};

const mutations = {
  addCompany(state, company) {
    state.companies.push(company);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  replaceCompany(state, company) {
    const index = state.companies.findIndex((c) => c.id == company.id);
    if (index != -1) {
      state.companies.splice(index, 1, company);
    }
    state.company = company;
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCompanies(state, companies) {
    state.companies = companies;
    state.waiting = false;
  },
  setCompany(state, company) {
    state.company = company;
    state.waiting = false;
  },
  setFivaldiCustomers(state, fivaldiCustomers) {
    state.fivaldiCustomers = fivaldiCustomers;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getCompany(state) {
    return state.company;
  },
};

const company = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default company;
