<template>
  <div class="flex w-full flex-col border-t bg-white">
    <navigation-item
      v-for="item in items"
      :key="item.index"
      :item="item"
      @click="navigateTo(item)"
    />
  </div>
</template>

<script>
import {
  BriefcaseIcon,
  BuildingOffice2Icon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/vue/24/outline";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import NavigationItem from "./NavigationItem.vue";
export default {
  components: { NavigationItem },
  setup() {
    const router = useRouter();

    const items = reactive([
      { icon: HomeIcon, label: "home", path: "/" },
      {
        icon: BriefcaseIcon,
        label: "companies",
        path: "/companies",
      },
      {
        icon: BuildingOffice2Icon,
        label: "partners",
        path: "/partners",
      },
      {
        icon: UsersIcon,
        label: "personnel",
        path: "/personnel",
      },
    ]);

    const navigateTo = (item) => {
      router.push(item.path);
    };

    return {
      items,
      navigateTo,
    };
  },
};
</script>
