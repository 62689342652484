import { db, functions } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    estate: {},
    estates: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async getEstate({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "estates", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const estate = docSnap.data();
        estate.id = docSnap.id;
        commit("setEstate", estate);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getPartnerEstates({ commit }, partnerId) {
    try {
      commit("wait");
      const getPartnerEstates = httpsCallable(functions, "getPartnerEstates");
      const res = await getPartnerEstates({ partnerId });

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("setEstates", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setEstate(state, estate) {
    state.estate = estate;
    state.waiting = false;
  },
  setEstates(state, estates) {
    state.estates = estates;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getEstate(state) {
    return state.estate;
  },
};

const estate = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default estate;
