<template>
  <div class="absolute">
    <div
      class="fixed top-0 left-0 z-40 max-h-full w-full overflow-y-auto border-b-4 border-indigo-500 bg-white md:z-50 md:block md:h-full md:w-72"
      :class="showNavigation ? 'block' : 'hidden'"
    >
      <div class="pt-20">
        <div class="relative">
          <img
            class="hidden h-36 w-full object-cover md:block"
            src="/assets/background.png"
          />
          <div
            class="absolute top-0 left-0 h-full w-full bg-indigo-500 opacity-25"
          ></div>
        </div>
        <div class="hidden h-1 bg-indigo-500 md:block"></div>
        <navigation-items />
      </div>
    </div>
    <div
      class="fixed top-0 left-0 z-50 flex h-20 w-full items-center justify-between bg-white shadow-md"
    >
      <div class="flex items-center gap-4 py-4 px-6">
        <button
          class="focus:outline-none flex h-8 w-8 items-center justify-center rounded-md bg-white focus:ring focus:ring-indigo-300 md:hidden"
          @click="toggleNavigation"
          @blur="closeNavigation"
        >
          <Bars3Icon class="w-full text-slate-500" />
        </button>
        <img class="h-12 object-contain" src="/assets/logo-dark.png" />
      </div>
      <navigation-profile />
    </div>
  </div>
</template>

<script>
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
} from "@heroicons/vue/24/outline";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import NavigationItems from "./NavigationItems.vue";
import NavigationProfile from "./NavigationProfile.vue";
export default {
  components: {
    ArrowRightOnRectangleIcon,
    Bars3Icon,
    NavigationItems,
    NavigationProfile,
  },
  setup() {
    const { t } = useI18n();

    const showNavigation = ref(false);
    const toggleNavigation = () => {
      showNavigation.value = !showNavigation.value;
    };

    return {
      showNavigation,
      t,
      toggleNavigation,
    };
  },
};
</script>
