import { db } from "@/firebase";
import router from "@/router";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const getDefaultState = () => {
  return {
    partner: {},
    partners: [],
    status: {},
    types: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async createPartner({ commit }, data) {
    try {
      commit("wait");
      const docRef = await addDoc(collection(db, "partners"), data);
      const partner = data;
      partner.id = docRef.id;

      commit("setSuccess", "partner_created");
      commit("addPartner", partner);
      router.push("/partners");
    } catch (error) {
      commit("failure", error);
    }
  },
  async getPartner({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "partners", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const partner = docSnap.data();
        partner.id = docSnap.id;
        commit("setPartner", partner);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async getPartners({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "partners"));
      const partners = querySnapshot.docs.map((doc) => {
        const partner = doc.data();
        partner.id = doc.id;
        return partner;
      });

      commit("setPartners", partners);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getTypes({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "types"));
      const types = querySnapshot.docs.map((doc) => {
        const type = doc.data();
        type.id = doc.id;
        return type;
      });
      commit("setTypes", types);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updatePartner({ commit }, { id, data }) {
    try {
      commit("wait");
      await updateDoc(doc(db, "partners", id), data);
      const partner = data;
      partner.id = id;

      commit("setSuccess", "partner_updated");
      commit("replacePartner", partner);
      router.push("/partners");
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addPartner(state, partner) {
    state.partners.push(partner);
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  replacePartner(state, partner) {
    const index = state.partners.findIndex((p) => p.id == partner.id);
    if (index != -1) {
      state.partners.splice(index, 1, partner);
    }
    state.partner = partner;
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setPartner(state, partner) {
    state.partner = partner;
    state.waiting = false;
  },
  setPartners(state, partners) {
    state.partners = partners;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  setTypes(state, types) {
    state.types = types;
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getPartner(state) {
    return state.partner;
  },
};

const partner = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default partner;
