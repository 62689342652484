import { db, functions } from "@/firebase";
import router from "@/router";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    status: {},
    personnel: [],
    user: {},
    users: [],
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async cancelInvite({ commit }, id) {
    try {
      commit("wait");
      const cancelAdmin = httpsCallable(functions, "cancelAdmin");
      const res = await cancelAdmin({ id });

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("cancelInvite", id);
      commit("setSuccess", "invite_cancelled");
    } catch (error) {
      commit("failure", error);
    }
  },
  async createEmployee({ commit }, data) {
    try {
      commit("wait");
      const createEmployee = httpsCallable(functions, "createAdmin");
      const res = await createEmployee(data);

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("addPersonnel", res.data.data);
      commit("setSuccess", "user_created");
      router.push("/personnel");
    } catch (error) {
      commit("failure", error);
    }
  },
  async createCompanyUser({ commit }, data) {
    try {
      commit("wait");
      const createEmployee = httpsCallable(functions, "createEmployee");
      const res = await createEmployee(data);

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("addUser", res.data.data);
      commit("setSuccess", "user_created");
      router.push(`/companies/${data.companyId}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async createPartnerUser({ commit }, data) {
    try {
      commit("wait");
      const createEmployee = httpsCallable(functions, "createEmployee");
      const res = await createEmployee(data);

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      commit("addUser", res.data.data);
      commit("setSuccess", "user_created");
      router.push(`/partners/${data.companyId}`);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getCompanyUsers({ commit }, companyId) {
    try {
      commit("wait");
      const q = query(
        collection(db, "users"),
        where("companyId", "==", companyId)
      );
      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });

      commit("setUsers", users);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getPersonnel({ commit }) {
    try {
      commit("wait");
      const q = query(collection(db, "users"), where("isAdmin", "==", true));
      const querySnapshot = await getDocs(q);
      const personnel = querySnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });

      commit("setPersonnel", personnel);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getUser({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const user = docSnap.data();
        user.id = docSnap.id;
        commit("setUser", user);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async toggleStatus({ commit }, id) {
    try {
      commit("wait");
      const toggleStatus = httpsCallable(functions, "toggleStatus");
      const res = await toggleStatus({ id });
      if (!res.data.success) {
        commit("failure", "user_status_change_failed");
        return;
      }

      commit("setSuccess", "user_status_updated");
      commit("setUser", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateUser({ commit }, data) {
    try {
      commit("wait");
      const updateUser = httpsCallable(functions, "updateUser");
      const res = await updateUser(data);
      if (!res.data.success) {
        commit("failure", "user_update_failed");
        return;
      }

      commit("setSuccess", "user_updated");
      commit("setUser", res.data.data);
      router.push("/personnel");
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  addPersonnel(state, user) {
    state.personnel.push(user);
    state.waiting = false;
  },
  addUser(state, user) {
    state.users.push(user);
    state.waiting = false;
  },
  cancelInvite(state, id) {
    const index = state.personnel.findIndex((user) => user.id === id);
    if (index > -1) {
      state.personnel.splice(index, 1);
    }
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setPersonnel(state, personnel) {
    state.personnel = personnel;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  setUser(state, user) {
    state.user = user;
    state.waiting = false;
  },
  setUsers(state, users) {
    state.users = users;
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
};

const user = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default user;
